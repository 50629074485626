@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --font-sans: ${sans.style.fontFamily};
}

@layer base {
  html {
    @apply h-full;
  }
  body {
    @apply bg-black;
  }
}
